import PropTypes from 'prop-types';

const ProfileIntroduction = props => {
  const { person, isCast } = props;

  return (
    <div className="introduction">
      <div className="role">
        <p>
          {person.role}
          {isCast ? <span>役</span> : ''}
        </p>
      </div>
      <div className="slash" />
      <div className="name">
        <p>{person.fullJapaneseName}</p>
        <p>{person.fullEnglishName}</p>
      </div>
    </div>
  );
};

ProfileIntroduction.propTypes = {
  person: PropTypes.shape({
    role: PropTypes.string.isRequired,
    fullJapaneseName: PropTypes.string.isRequired,
    fullEnglishName: PropTypes.string.isRequired
  }).isRequired,
  isCast: PropTypes.bool.isRequired
};

export default ProfileIntroduction;
