import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import HamburgerMenu from './HamburgerMenu';
import Logo from './Logo';
import NavigationLinks from './NavigationLinks';
import { valueOnCondition } from '../../helper/common_helper';

const NavigationBar = ({ isPageLoaded }) => {
  const navigationLinksRef = useRef(null);
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [activeLink, setActiveLink] = useState('');
  const [hamburgerClicked, setHamburgerClicked] = useState(false);

  const PATHNAMES = [
    {
      text: 'WHOLE',
      path: 'top'
    },
    [
      {
        text: '予告編',
        path: 'trailer'
      },
      {
        text: 'イントロダクション',
        path: 'introduction'
      },
      {
        text: 'ストーリー',
        path: 'story'
      },
      {
        text: 'キャスト',
        path: 'cast'
      },
      {
        text: 'スタッフ',
        path: 'staff'
      },
      {
        text: '応援コメント',
        path: 'comments'
      },
      {
        text: '劇場情報',
        path: 'theater-information'
      },
      {
        text: '自主上映のご案内',
        path: 'public-screenings'
      },
      {
        text: 'ご支援いただいた皆様',
        path: 'special-thanks'
      }
    ]
  ];

  const jumpToContainer = event => {
    event.preventDefault();
    setActiveLink(event.target.id);

    document.getElementById(event.currentTarget.href.split('#')[1]).scrollIntoView();
  };

  const navLinks = () => {
    if (browserWidth > 1130) {
      return (
        <NavigationLinks
          navigationLinks={PATHNAMES[1]}
          navigationLinksRef={navigationLinksRef}
          jumpToContainer={jumpToContainer}
          activeLink={activeLink}
        />
      );
    }

    return (
      <HamburgerMenu
        navigationLinks={PATHNAMES[1]}
        navigationLinksRef={navigationLinksRef}
        jumpToContainer={jumpToContainer}
        activeLink={activeLink}
        updateActiveLinkOnScroll={updateActiveLinkOnScroll}
        hamburgerClicked={hamburgerClicked}
        setHamburgerClicked={setHamburgerClicked}
      />
    );
  };

  const updateActiveLinkOnScroll = () => {
    let currentContainer = '';

    document.querySelectorAll('.container').forEach(container => {
      if (document.body.scrollTop >= container.offsetTop) {
        currentContainer = container.getAttribute('id');
      }
    });

    setActiveLink(`${currentContainer}-link`);
  };

  const updateBrowserWidth = () => setBrowserWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('scroll', updateActiveLinkOnScroll, true);
    window.addEventListener('resize', updateBrowserWidth, true);
  }, []);

  return (
    <nav>
      <div className={`navigation-contents ${valueOnCondition(isPageLoaded, 'loaded')}`}>
        <Logo
          logoInformation={PATHNAMES[0]}
          jumpToContainer={jumpToContainer}
          hamburgerClicked={hamburgerClicked}
          setHamburgerClicked={setHamburgerClicked}
          browserWidth={browserWidth}
        />
        {navLinks()}
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  isPageLoaded: PropTypes.bool.isRequired
};

export default NavigationBar;
