import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';
import CommentText from './CommentText';

const Comments = props => {
  const commentors = props.commentors.sort((currentCommentor, nextCommentor) => (
    currentCommentor.order - nextCommentor.order
  ));

  return (
    <div className="container" id="comments">
      <SectionTitle japaneseTitle="応援コメント" englishTitle="Comments" />
      <div id="comments-text-container">
        {commentors.map(commentor => (
          <CommentText
            commentor={commentor.fullName}
            occupation={commentor.occupation}
            notableWork={commentor.notableWork}
            comment={commentor.comment}
          />
        ))}
      </div>
    </div>
  );
};

Comments.propTypes = {
  commentors: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Comments;
