import PropTypes from 'prop-types';

const RoadshowInformation = props => {
  const TABLE_HEADERS = ['都道府県', '劇場名', '上映期間'];
  const { region, theaters } = props;

  const formatDate = airDate => {
    if (airDate === undefined) return '時期未定';

    let JSTDatetime;
    if (typeof airDate === 'string') {
      JSTDatetime = new Date(airDate).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
    } else {
      JSTDatetime = airDate.toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' });
    }

    const convertedDatetime = new Date(JSTDatetime);
    const year = convertedDatetime.getFullYear();
    const month = convertedDatetime.getMonth() + 1;
    const date = convertedDatetime.getDate();
    const dayOfWeek = convertedDatetime.getDay();
    const day = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek];

    return `${year}年${month}月${date}日(${day})`;
  };

  const airDateRange = theater => {
    if (theater.startAirDate === undefined && theater.finalAirDate === undefined) return '時期未定';
    if (new Date() > new Date(theater.finalAirDate)) return '上映終了';

    return `${formatDate(theater.startAirDate)} ~ ${formatDate(theater.finalAirDate)}`;
  };

  const individualDates = theater => {
    const airDates = theater.individualDates
      .map(date => new Date(date))
      .sort((currentDate, nextDate) => nextDate - currentDate);

    if (new Date() > new Date(airDates[airDates.length - 1])) return '上映終了';

    return theater.individualDates.map(date => formatDate(date)).join('、');
  };

  const airDates = theater => {
    if (theater.individualDates !== undefined
    && (theater.startAirDate === undefined && theater.finalAirDate === undefined)
    ) return individualDates(theater);

    return airDateRange(theater);
  };

  const inRegion = theaterRegion => theaterRegion === region;

  const theaterData = theater => (
    <tr key={theater.name}>
      <td>{theater.prefecture}</td>
      <td><a href={theater.url} target="_blank" rel="noreferrer">{theater.name}</a></td>
      <td>{airDates(theater)}</td>
    </tr>
  );

  return (
    <div className="roadshow-table" key={`theater-${region}`}>
      <p>{region}</p>
      <table>
        <colgroup>
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            {TABLE_HEADERS.map(tableHeader => <th key={tableHeader}>{tableHeader}</th>)}
          </tr>
          {theaters.map(theater => (inRegion(theater.region) ? theaterData(theater) : <></>))}
        </tbody>
      </table>
    </div>
  );
};

RoadshowInformation.propTypes = {
  region: PropTypes.string.isRequired,
  theaters: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RoadshowInformation;
