import { FacebookShareButton, LineShareButton, TwitterShareButton } from 'react-share';

const reactShareComponent = icon => {
  const pathname = window.location.href;

  switch (icon) {
    case 'facebook':
      return (
        <FacebookShareButton
          url={pathname}
          key={icon}
          id="fb-share"
        >
          <i className={`fab fa-${icon}`} />
        </FacebookShareButton>
      );
    case 'line':
      return (
        <LineShareButton url={pathname} key={icon}>
          <i className={`fab fa-${icon}`} />
        </LineShareButton>
      );
    case 'twitter':
      return (
        <TwitterShareButton
          url={pathname}
          key={icon}
        >
          <i className={`fab fa-${icon}`} />
        </TwitterShareButton>
      );
    default:
  }

  return '';
};

const shareIcons = icons => (
  <div className="icons-container">
    {icons.map(icon => reactShareComponent(icon))}
  </div>
);

const socialNetworkLinks = socialNetworks => (
  <>
    {socialNetworks.map(socialNetwork => (
      <a href={socialNetwork.path} target="_blank" rel="noreferrer" key={socialNetwork.name}>
        <i className={`fab fa-${socialNetwork.name}`} key={socialNetwork.name} />
      </a>
    ))}
  </>
);

export { shareIcons, socialNetworkLinks };
