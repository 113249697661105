import PropTypes from 'prop-types';

const Top = ({ isPageLoaded }) => {
  const loaded = isPageLoaded ? 'loaded' : '';

  return (
    <div id="top">
      <img
        src="https://res.cloudinary.com/wholemovie/image/upload/v1627513074/laurels_vlwxq9.png"
        alt="laurels"
        className={loaded}
      />
      <div className={`top-title ${loaded}`}>
        <span>ホール</span>
        <h1>WHOLE</h1>
      </div>
      <p className={loaded}>欠けていた半分を満たす出会い</p>
    </div>
  );
};

Top.propTypes = {
  isPageLoaded: PropTypes.bool.isRequired
};

export default Top;
