import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import PropTypes from 'prop-types';
import ProfileIntroduction from './ProfileIntroduction';

function Profile(props) {
  const { person, isCast } = props;

  return (
    <div className={person.type === 'main' ? 'main-profile' : 'sub-profile'}>
      <div className="profile-pic">
        <img src={person.profilePicture.fields.file.url} alt="person-profile" />
      </div>
      <ProfileIntroduction person={person} isCast={isCast} />
      <div
        className="description"
        dangerouslySetInnerHTML={{ __html: documentToHtmlString(person.description) }}
      />
    </div>
  );
}

Profile.propTypes = {
  person: PropTypes.shape({
    type: PropTypes.string.isRequired,
    profilePicture: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string
        })
      })
    }),
    role: PropTypes.string.isRequired,
    fullJapaneseName: PropTypes.string.isRequired,
    fullEnglishName: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  isCast: PropTypes.bool.isRequired
};

export default Profile;
