const actorTags = actors => (
  actors.map(actor => <span key={actor.name}>{actor}</span>)
);

const staffTags = staffs => (
  staffs.map(staff => (
    <span key={staff.name}>
      {staff.position}
      ：
      {staff.name}
    </span>
  ))
);

export { actorTags, staffTags };
