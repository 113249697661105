import { Timeline } from 'react-twitter-widgets';
import FacebookPage from './FacebookPage';

const Timelines = () => (
  <div className="timeline-container">
    <FacebookPage />
    <Timeline
      dataSource={{
        sourceType: 'profile',
        screenName: 'WHOLE_Film21'
      }}
      options={{
        width: '400px',
        height: '500px',
        id: 'profile:WHOLE_Film21'
      }}
    />
  </div>
);

export default Timelines;
