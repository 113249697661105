import inProduction from '../helper/environment_helper';

const FacebookPage = () => {
  const width = 400;
  const height = 500;
  const appId = inProduction() ? process.env.REACT_APP_APP_ID : '102679255707';

  return (
    <>
      <iframe
        src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F%25E6%2598%25A0%25E7%2594%25BBWhole%25E3%2583%259B%25E3%2583%25BC%25E3%2583%25AB-101420385571466%2F%3Fref%3Dbookmarks&tabs=timeline&width=${width}&height=${height}&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=${appId}`}
        height={`${height}px`}
        width={`${width}px`}
        scrolling="yes"
        frameBorder="0"
        allowFullScreen
        title="facebook-page"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </>
  );
};

export default FacebookPage;
