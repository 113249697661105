import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';

const SpecialThanks = props => (
  <div className="container" id="special-thanks">
    <SectionTitle japaneseTitle="ご支援いただいた皆様" englishTitle="Special Thanks" />
    <div id="thank-you-message">
      <p>
        WHOLE製作チームは今回の作品を作成するにあたり、様々な方々に支援していただきました。改めまして、ご支援・ご協力
        いただいた皆様に深く御礼申し上げます。
      </p>
    </div>
    <div id="supporters">
      {props.supporters.map(supporter => (
        <div className="supporter">
          <p>{supporter.fullName}</p>
        </div>
      ))}
    </div>
  </div>
);

SpecialThanks.propTypes = {
  supporters: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SpecialThanks;
