import PropTypes from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Marquee from 'react-fast-marquee';
import SectionTitle from './common/SectionTitle';

const Introduction = props => {
  const { introduction, marqueeImages } = props;
  const groupImages = position => marqueeImages.filter(image => image.position === position);

  return (
    <div className="container" id="introduction">
      <SectionTitle japaneseTitle="イントロダクション" englishTitle="Introduction" />
      <div className="marquee-wrapper">
        <Marquee direction="right" speed={10} style={{ 'z-index': 0 }}>
          {groupImages('top').map(image => (
            <img className="marquee-image" src={image.image.fields.file.url} alt="marquee" />
          ))}
        </Marquee>
        <Marquee direction="left" speed={10} style={{ 'z-index': 0 }}>
          {groupImages('bottom').map(image => (
            <img className="marquee-image" src={image.image.fields.file.url} alt="marquee" />
          ))}
        </Marquee>
      </div>
      <div id="intro-text" dangerouslySetInnerHTML={{ __html: documentToHtmlString(introduction.content) }} />
    </div>
  );
};

Introduction.propTypes = {
  introduction: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  marqueeImages: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Introduction;
