import PropTypes from 'prop-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer/dist/rich-text-html-renderer.es5';

function CommentText(props) {
  const { comment, commentor, occupation, notableWork } = props;

  const formatNotableWork = () => (notableWork ? `『${notableWork}』` : '');

  return (
    <div className="comment">
      <div
        dangerouslySetInnerHTML={{ __html: documentToHtmlString(comment) }}
        className="comment-text"
      />
      <div className="commentor">
        <span className="commentor-text">
          {commentor}
          (
          {occupation}
          {formatNotableWork()}
          )
        </span>
      </div>
    </div>
  );
}

CommentText.propTypes = {
  comment: PropTypes.arrayOf(PropTypes.object).isRequired,
  commentor: PropTypes.string.isRequired,
  occupation: PropTypes.string,
  notableWork: PropTypes.string
};

CommentText.defaultProps = {
  occupation: '',
  notableWork: ''
};

export default CommentText;
