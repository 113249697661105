import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const LoadingPage = props => {
  const { imageLoaded, isPageLoaded, percentageLoaded, TOTAL_IMAGES } = props;
  const INITIAL_WIDTH = 80;
  const increment = INITIAL_WIDTH / TOTAL_IMAGES;
  const [initialWidth, setInitialWidth] = useState(80);

  useEffect(() => {
    if (imageLoaded) setInitialWidth(initialWidth - increment);
  }, [imageLoaded]);

  useEffect(() => {
    if (isPageLoaded) document.body.style.overflowY = 'scroll';
  }, [isPageLoaded]);

  return (
    <div className={`loading-page ${isPageLoaded ? 'loaded' : ''}`}>
      <div className="moon">
        <div className="moon-cover" style={{ width: `${initialWidth}%` }} />
      </div>
      <h1>WHOLE</h1>
      <p>{percentageLoaded}</p>
    </div>
  );
};

LoadingPage.propTypes = {
  imageLoaded: PropTypes.number.isRequired,
  isPageLoaded: PropTypes.bool.isRequired,
  percentageLoaded: PropTypes.string.isRequired,
  TOTAL_IMAGES: PropTypes.number.isRequired
};

export default LoadingPage;
