const contentful = require('contentful');

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
});

const isModelName = (record, model) => record.sys.contentType.sys.id === model;

const singleRecord = (records, model) => records.find(record => isModelName(record, model)).fields;

const multipleRecords = (records, model) => records.filter(record => isModelName(record, model))
  .map(record => record.fields);

export { client, isModelName, singleRecord, multipleRecords };
