import imagesLoaded from 'imagesloaded';
import { useEffect, useState } from 'react';
import Top from './components/Top';
import './stylesheets/application.scss';
import TheaterInformation from './components/TheaterInformation';
import { client, multipleRecords, singleRecord } from './components/common/api/contentful';
import Cast from './components/Cast';
import Introduction from './components/Introduction';
import Story from './components/Story';
import Trailer from './components/Trailer';
import Comments from './components/Comments';
import Staff from './components/Staff';
import NavigationBar from './components/navigation/NavigationBar';
import LoadingPage from './components/LoadingPage';
import SpecialThanks from './components/SpecialThanks';
import FooterInformation from './components/FooterInformation';
import PublicScreenings from './components/PublicScreenings';

function App() {
  const [casts, setCasts] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [commentors, setCommentors] = useState([]);
  const [introduction, setIntroduction] = useState({});
  const [story, setStory] = useState({});
  const [theaters, setTheaters] = useState([]);
  const [supporters, setSupporters] = useState([]);
  const [marqueeImages, setMarqueeImages] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(0);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const TOTAL_IMAGE_TAGS = document.images.length;
  const TOTAL_BACKGROUND_IMAGES = element => {
    const srcChecker = /url\(\s*?['"]?\s*?(\S+?)\s*?["']?\s*?\)/i;
    return Array.from(
      Array.from(element.querySelectorAll('*')).reduce((collection, node) => {
        const source = window.getComputedStyle(node, null).getPropertyValue('background-image');
        const match = srcChecker.exec(source);
        if (match) {
          collection.add(match[1]);
        }
        return collection;
      }, new Set())
    ).length;
  };
  const TOTAL_IMAGES = TOTAL_IMAGE_TAGS + TOTAL_BACKGROUND_IMAGES(document);
  const percentageLoaded = `${Math.round(((imageLoaded / TOTAL_IMAGES) * 100))}%`;

  useEffect(() => {
    client.getEntries({ order: 'sys.createdAt' })
      .then(response => {
        setCasts(multipleRecords(response.items, 'cast'));
        setCommentors(multipleRecords(response.items, 'commentor'));
        setIntroduction(singleRecord(response.items, 'introduction'));
        setStory(singleRecord(response.items, 'story'));
        setTheaters(multipleRecords(response.items, 'theater'));
        setStaffs(multipleRecords(response.items, 'staff'));
        setSupporters(multipleRecords(response.items, 'supporter'));
        setMarqueeImages(multipleRecords(response.items, 'slideshowImage'));

        let totalImagesLoaded = 0;
        imagesLoaded(document.getElementById('root'), { background: true }).on('progress', () => {
          if (TOTAL_IMAGES !== 0) totalImagesLoaded += 1;
          setImageLoaded(totalImagesLoaded);
        });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (imageLoaded === TOTAL_IMAGES) {
      setTimeout(() => {
        setIsPageLoaded(true);
      }, 700);
    }
  }, [imageLoaded]);

  return (
    <>
      <LoadingPage
        imageLoaded={imageLoaded}
        isPageLoaded={isPageLoaded}
        percentageLoaded={percentageLoaded}
        TOTAL_IMAGES={TOTAL_IMAGES}
      />
      <NavigationBar isPageLoaded={isPageLoaded} />
      <Top isPageLoaded={isPageLoaded} />
      <Trailer />
      <Introduction introduction={introduction} marqueeImages={marqueeImages} />
      <Story story={story} />
      <Cast casts={casts} />
      <Staff staffs={staffs} />
      <Comments commentors={commentors} />
      <TheaterInformation theaters={theaters} />
      <PublicScreenings />
      <SpecialThanks supporters={supporters} />
      <FooterInformation />
    </>
  );
}

export default App;
