import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';
import Profile from './common/Profile';
import ProfileIntroduction from './common/ProfileIntroduction';

const Staff = props => {
  const staffs = staffType => props.staffs.filter(staff => staff.type === staffType);

  return (
    <div className="container" id="staff">
      <SectionTitle japaneseTitle="スタッフ" englishTitle="Staff" />
      <div id="main-staffs">
        {staffs('main').map(staff => (
          <Profile person={staff} key={staff.fullEnglishName} isCast={false} />
        ))}
      </div>
      <div id="sub-staffs">
        {staffs('sub').map(staff => <ProfileIntroduction person={staff} isCast={false} />)}
      </div>
    </div>
  );
};

Staff.propTypes = {
  staffs: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Staff;
