import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';
import Profile from './common/Profile';

const Cast = props => {
  const casts = castType => props.casts.filter(cast => cast.type === castType);

  return (
    <div className="container" id="cast">
      <SectionTitle japaneseTitle="キャスト" englishTitle="Cast" />
      <div id="main-casts">
        {casts('main').map(cast => <Profile person={cast} key={cast.fullEnglishName} isCast />)}
      </div>
      <div id="sub-casts">
        {casts('sub').map(cast => <Profile person={cast} key={cast.fullEnglishName} isCast />)}
      </div>
    </div>
  );
};

Cast.propTypes = {
  casts: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default Cast;
