import PropTypes from 'prop-types';

const SectionTitle = props => {
  const { japaneseTitle, englishTitle } = props;

  return (
    <div className="section-title">
      <h2>{ japaneseTitle }</h2>
      <span>{ englishTitle }</span>
    </div>
  );
};

SectionTitle.propTypes = {
  japaneseTitle: PropTypes.string.isRequired,
  englishTitle: PropTypes.string.isRequired
};

export default SectionTitle;
