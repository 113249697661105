import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';

const Story = props => (
  <div className="container" id="story">
    <SectionTitle japaneseTitle="ストーリー" englishTitle="Story" />
    <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(props.story.content) }} />
  </div>
);

Story.propTypes = {
  story: PropTypes.shape({
    content: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default Story;
