import PropTypes from 'prop-types';
import { changeOverflowY } from '../../helper/common_helper';

const NavigationLinks = props => {
  const {
    navigationLinks,
    navigationLinksRef,
    jumpToContainer,
    hamburgerClicked,
    setHamburgerClicked,
    activeLink
  } = props;

  const updateClicked = event => {
    if (hamburgerClicked !== undefined) {
      setHamburgerClicked(!hamburgerClicked);
      changeOverflowY(document.body, 'scroll');
    }

    jumpToContainer(event);
  };

  return (
    <>
      <div className="navigation-links" ref={navigationLinksRef}>
        {navigationLinks.map(pathname => (
          <a
            href={`#${pathname.path}`}
            onClick={updateClicked}
            className={`nav-links ${activeLink === `${pathname.path}-link` ? 'active' : ''}`}
            id={`${pathname.path}-link`}
          >
            {pathname.text}
          </a>
        ))}
      </div>
    </>
  );
};

NavigationLinks.propTypes = {
  navigationLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigationLinksRef: PropTypes.shape({
    current: PropTypes.shape({
      childElementCount: PropTypes.number.isRequired
    })
  }).isRequired,
  jumpToContainer: PropTypes.func.isRequired,
  hamburgerClicked: PropTypes.bool,
  setHamburgerClicked: PropTypes.func,
  activeLink: PropTypes.string.isRequired
};

NavigationLinks.defaultProps = {
  hamburgerClicked: undefined,
  setHamburgerClicked: undefined
};

export default NavigationLinks;
