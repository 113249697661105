import PropTypes from 'prop-types';
import NavigationLinks from './NavigationLinks';
import { changeOverflowY } from '../../helper/common_helper';

const HamburgerMenu = props => {
  const {
    navigationLinks,
    navigationLinksRef,
    scrollToContainer,
    jumpToContainer,
    activeLink,
    hamburgerClicked,
    setHamburgerClicked
  } = props;

  const toggleMenu = () => {
    if (hamburgerClicked) {
      changeOverflowY(document.body, 'scoll');
    } else {
      changeOverflowY(document.body, 'hidden');
    }

    setHamburgerClicked(!hamburgerClicked);
  };

  return (
    <div id="hamburger-container">
      <button className={`hamburger hamburger--squeeze ${hamburgerClicked ? 'is-active' : ''}`} type="button" onClick={toggleMenu}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <div className={`navigation-contents ${hamburgerClicked ? 'active' : ''}`}>
        <NavigationLinks
          navigationLinks={navigationLinks}
          navigationLinksRef={navigationLinksRef}
          scrollToContainer={scrollToContainer}
          hamburgerClicked={hamburgerClicked}
          setHamburgerClicked={setHamburgerClicked}
          jumpToContainer={jumpToContainer}
          activeLink={activeLink}
        />
      </div>
    </div>
  );
};

HamburgerMenu.propTypes = {
  navigationLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigationLinksRef: PropTypes.shape({
    current: PropTypes.shape({
      childElementCount: PropTypes.number.isRequired
    })
  }).isRequired,
  scrollToContainer: PropTypes.func.isRequired,
  jumpToContainer: PropTypes.func.isRequired,
  activeLink: PropTypes.string.isRequired,
  hamburgerClicked: PropTypes.bool.isRequired,
  setHamburgerClicked: PropTypes.func.isRequired
};

export default HamburgerMenu;
