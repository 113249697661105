import PropTypes from 'prop-types';
import SectionTitle from './common/SectionTitle';
import RoadshowInformation from './RoadshowInformation';

const TheaterInformation = props => {
  const { theaters } = props;

  const regions = theaters.map(theater => theater.region)
    .filter((region, index, self) => self.indexOf(region) === index);

  return (
    <div className="container" id="theater-information">
      <SectionTitle japaneseTitle="劇場情報" englishTitle="Theater Information" />
      {regions.map(region => (
        <RoadshowInformation
          region={region}
          theaters={theaters}
        />
      ))}
    </div>
  );
};

TheaterInformation.propTypes = {
  theaters: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TheaterInformation;
