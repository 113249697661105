import { actorTags, staffTags } from '../helper/footer_helper';
import { socialNetworkLinks } from '../helper/icon_helper';
import Timelines from './Timelines';

const Footer = () => {
  const mainActors = ['サンディー 海', '川添 ウスマン'];
  const supportActors = ['伊吹 葵', '菊池 明明', '尾崎 紅', '中山 佳祐', '松田 顕生'];
  const mainStaffs = [
    {
      name: '川添 ビイラル',
      position: '監督・編集'
    },
    {
      name: '川添 ウスマン',
      position: '脚本'
    },
    {
      name: '川添 ウスマン　川添 ビイラル',
      position: 'プロデューサー'
    }
  ];
  const subStaffGroup1 = [
    {
      name: 'JRT　中村 礼　YURAPOi ゆらぽぃ',
      position: 'アソシエイトプロデューサー'
    },
    {
      name: '武井俊幸',
      position: '撮影・照明'
    },
    {
      name: '松野泉',
      position: '録音'
    }
  ];
  const subStaffGroup2 = [
    {
      name: '井辻悠輔',
      position: '制作担当'
    },
    {
      name: '稗田梓',
      position: 'メイク・衣装'
    },
    {
      name: '石田葉子',
      position: '撮影助手'
    },
    {
      name: '前田育穂',
      position: '監督助手'
    },
    {
      name: '藤原達昭　青木ありさ',
      position: '助監督・美術'
    }
  ];
  const subStaffGroup3 = [
    {
      name: '西岡優一郎',
      position: 'ケータリング'
    },
    {
      name: 'ガブリエラ・リエ・ヨシモト・クルーズ',
      position: 'スチール'
    }
  ];
  const themeSong = {
    name: "Wouldn't It Be Great",
    artist: 'rei brown'
  };
  const officialSocials = [
    {
      name: 'facebook',
      path: 'https://www.facebook.com/%E6%98%A0%E7%94%BBWhole%E3%83%9B%E3%83%BC%E3%83%AB-101420385571466/?ref=bookmarks'
    },
    {
      name: 'twitter',
      path: 'https://twitter.com/WHOLE_Film21'
    },
    {
      name: 'instagram',
      path: 'https://www.instagram.com/078firm/'
    }
  ];
  const copyrightInformation = '© 078　2019年 / 日本 / カラー / 44分 / 16:9 / Stereo';

  return (
    <footer className="container">
      <div className="casts">
        <div className="main-participants">
          {actorTags(mainActors)}
        </div>
        <div className="support-participants">
          {actorTags(supportActors)}
        </div>
      </div>
      <div className="staffs">
        <div className="main-participants">
          {staffTags(mainStaffs)}
        </div>
        <div className="support-participants" id="support-participants-group-1">
          {staffTags(subStaffGroup1)}
        </div>
        <div className="support-participants" id="support-participants-group-2">
          {staffTags(subStaffGroup2)}
        </div>
        <div className="support-participants" id="support-participants-group-3">
          {staffTags(subStaffGroup3)}
        </div>
      </div>
      <div className="theme-song">
        <p>
          主題歌：「
          {themeSong.name}
          」
          {themeSong.artist}
        </p>
      </div>
      <div className="icons-container" id="footer-icons-container">
        {socialNetworkLinks(officialSocials)}
      </div>
      <div className="copywright-information">
        <p>{copyrightInformation}</p>
      </div>
      <Timelines />
    </footer>
  );
};

export default Footer;
