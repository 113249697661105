import SectionTitle from './common/SectionTitle';

const PublicScreenings = () => (
  <div className="container" id="public-screenings">
    <SectionTitle
      japaneseTitle="自主上映のご案内"
      englishTitle="Public Screenings"
    />
    <div className="main-contents">
      <img src="/public-screening.jpeg" alt="public-screening" />
      <div className="texts">
        <h2>映画『WHOLE/ホール』をあなたの選んだ場所で特別上映</h2>
        <p>
          教室、オフィス、公民館、飲食店など、ご希望の場所での上映を受け付けております。
          <br />
          映画の鑑賞・参加者同士の対話を通じて、多様性を深め、考える場を作ってみませんか?
          <br />
          ご質問やご不明点がありましたら適切に下記ボタンをクリックし、フォームの備考欄からお問い合わせください。
        </p>
      </div>
      <div className="links">
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeUQ8XwVhkN4bBb3koavyBJoMLkZNt-It62_XVKkux2OaBX_w/viewform?usp=pp_url"
          target="_blank"
          rel="noreferrer"
          className="blue-button"
        >
          日本国内で上映を申込む
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSekH5RM1z_Hd1x6Te0urCFKr-l_r1b66QO-kkUAzotQJ62RdA/viewform"
          target="_blank"
          rel="noreferrer"
          className="white-button"
        >
          Apply for Overseas Screening
        </a>
      </div>
      <small>* 海外上映をご希望の方は右側のボタンをクリックしてください</small>
    </div>
  </div>
);

export default PublicScreenings;
