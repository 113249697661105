import SectionTitle from './common/SectionTitle';

const Trailer = () => (
  <div className="container" id="trailer">
    <SectionTitle japaneseTitle="予告編" englishTitle="Trailer" />
    <div className="content">
      <div className="texts">
        <h2>Vimeo オンデマンドで配信中！</h2>
        <p>
          『WHOLE／ホール』を視聴するには
          <a href="https://vimeo.com/ondemand/wholefilm" target="_blank" rel="noreferrer">こちら</a>
          をクリック
        </p>
      </div>
      <div className="video-container">
        <iframe
          title="trailer"
          src="https://player.vimeo.com/video/888360223?h=c8b34d6cde"
          allow="fullscreen; picture-in-picture"
          allowFullScreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </div>
  </div>
);

export default Trailer;
