import PropTypes from 'prop-types';
import { changeOverflowY } from '../../helper/common_helper';

const Logo = props => {
  const {
    jumpToContainer,
    hamburgerClicked,
    setHamburgerClicked,
    browserWidth,
    logoInformation
  } = props;

  const closeNavigation = event => {
    jumpToContainer(event);

    if (browserWidth < 1130 && hamburgerClicked) {
      changeOverflowY(document.body, 'scroll');
      setHamburgerClicked(false);
    }
  };

  return (
    <a href={`#${logoInformation.path}`} onClick={closeNavigation} id="logo">
      {logoInformation.text}
    </a>
  );
};

Logo.propTypes = {
  logoInformation: PropTypes.shape({
    path: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired,
  jumpToContainer: PropTypes.func.isRequired,
  hamburgerClicked: PropTypes.bool.isRequired,
  setHamburgerClicked: PropTypes.func.isRequired,
  browserWidth: PropTypes.number.isRequired
};

export default Logo;
